<template>
  <div class="vc-wrapper">
    <Header />
    <div class="vc-management__list">
      <div class="loader" v-if="isLoading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <AccessDenied v-if="error.status === 403" />
        <div v-else>
          <div v-if="getAllLtiUnits.length > 0">
            <b-table
              small
              borderless
              responsive
              hover
              :items="getAllLtiUnits"
              :fields="fields"
              thead-tr-class="vcm-list__table--tr"
              class="vcm-list__table"
              @row-hovered="onRowHovered"
              @row-unhovered="onRowUnHovered"
            >
              <template #cell(display_name)="data">
                <h5
                  class="vcm-list__table--name text-truncate"
                  :title="data.item.display_name"
                >
                  {{ data.item.display_name }}
                </h5>
              </template>
              <template #cell(instructors)="data">
                <p class="learner-text">
                  {{
                    data.item.instructors.length > 0
                      ? data.item.instructors.join(", ")
                      : "-"
                  }}
                </p>
              </template>

              <template #cell(actions)="data">
                <a
                  class="vcm-list__table--attendance"
                  @click="onMarkAttendance(data.item)"
                  >Mark attendance</a
                >
              </template>
              <template #cell(options)="data">
                <a
                  class="vcm-list__table--export"
                  @click="
                    exportEnrollments(data.item, data.item.id, 'attendance')
                  "
                >
                  <img :src="exportIcon(data.item)" alt="export" />
                </a>
              </template>
            </b-table>
          </div>
          <EmptyState v-else component-type="attendance" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VirtualClassMixin from "./mixin";

import Header from "./Shared/Header.vue";
import AccessDenied from "./Shared/AccessDenied.vue";
import EmptyState from "./Shared/EmptyState.vue";
export default {
  components: { Header, AccessDenied, EmptyState },
  mixins: [VirtualClassMixin],
  data() {
    return {
      fields: [
        {
          key: "display_name",
          label: "Name",
          thStyle: { width: "35%" }
        },
        {
          key: "instructors",
          label: "Instructor"
        },
        {
          key: "num_attendees",
          label: "Attendees"
        },
        {
          key: "num_absentees",
          label: "Absentees"
        },
        "actions",
        {
          key: "options",
          label: ""
        }
      ],
      isRowHovered: false,
      isLoading: false
    };
  },
  created() {
    this.listLtiUnits();
    document.title = "Attendance";
  },
  methods: {
    onMarkAttendance(data) {
      this.$router.push({
        path: `/course/${this.courseId}/${data.id}/attendance`,
        query: { name: data.display_name }
      });
    }
  }
};
</script>
<style lang="scss">
.vc-wrapper {
  .vc-management__list {
    margin-top: 30px;
    .vcm-list__table--export {
      cursor: pointer;
    }
  }
}
</style>
